@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);

:root {
    --primaryColor: #70919b;
    --secondaryColor: #6787dc;
    --inputBoxColor: #f3f3f3;
    --placeHolderColor: #949494;
    --errorColor: #ea0c26;
    --textOnPrimaryColor: #ffffff;
    --primaryHoverColor: #8eb8c6;
    --iconColor: #d1d1d1;
    --textColor: #555d60;
    --boxshawdow: 0px 5px 20px rgba(0, 0, 0, 0.06);
}

* {
    padding: 0;
    margin: 0;
}
html {
    font-family: "Lato", sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.test_area {
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
    width: 15rem;
}

:root {
    --primaryColor: #70919b;
    --secondaryColor: #6787dc;
    --inputBoxColor: #f3f3f3;
    --placeHolderColor: #949494;
    --errorColor: #ea0c26;
    --textOnPrimaryColor: #ffffff;
    --primaryHoverColor: #8eb8c6;
    --iconColor: #d1d1d1;
    --textColor: #555d60;
    --boxshawdow: 0px 5px 20px rgba(0, 0, 0, 0.06);
}

.Card_card__3F8np {
    box-shadow: var(--boxshawdow);
    background: #ffffff;
    border-radius: 1rem;
    overflow: hidden;
}

.AuthPanel_back_button__1D0uD {
    position: absolute;
    left: 1.375rem;
    top: 2rem;
    cursor: pointer;
}

@media screen and (min-width: 1000px) {
    .AuthPanel_auth_panel___TMiv {
        padding: 0 4rem;
        padding-bottom: 5rem;
        position: relative;
    }
    .AuthPanel_back_button__1D0uD {
        top: 1.375rem;
    }
}

.CoverPage_page__1u8F5 {
    display: grid;
    grid-template:
        "." 7.5rem
        "imgA" auto
        "." 6rem
        "titleA" auto
        "." 4rem
        "login_buttonA" auto
        "." 1rem
        "register_buttonA" auto
        "." 5.75rem /100%;
    width: 18rem;
    margin: 0 auto;
    justify-items: center;
}

.CoverPage_logo__32V4i {
    grid-area: imgA;
}

.CoverPage_title__2Ow1y {
    grid-area: titleA;
    color: var(--primaryColor);
    font-size: 2rem;
    text-align: center;
}

.CoverPage_login_button__1QkoG,
.CoverPage_register_button__13r-t {
    width: 100%;
}

.CoverPage_login_button__1QkoG {
    grid-area: login_buttonA;
}

.CoverPage_register_button__13r-t {
    grid-area: register_buttonA;
}

@media screen and (min-width: 768px) {
    .CoverPage_page__1u8F5 {
        margin-top: 8rem;
    }
}
@media screen and (min-width: 1000px) {
    .CoverPage_page__1u8F5 {
        margin-top: 0rem;
    }
}

.LoginPage_page__3OpUi {
    display: grid;
    grid-template:
        "." 6rem
        "titleA" auto
        "." 4.875rem
        "emailA" auto
        "." 0.8rem
        "passwordA" auto
        "." 0.8rem
        "rememberA" auto
        "." 1rem
        "serverA" auto
        "loginA" auto
        "." 5.75rem / 100%;
    width: 18rem;
    margin: 0 auto;
}

.LoginPage_error__Ewq5E {
    grid-area: serverA;
    font-size: 0.75rem;
    color: var(--errorColor);
    margin-bottom: 3.3rem;
    padding-left: 0rem;
}
.LoginPage_title__2uBwH {
    grid-area: titleA;
    color: var(--primaryColor);
    font-size: 2rem;
    text-align: center;
}

.LoginPage_email__L4KK4 {
    grid-area: emailA;
}

.LoginPage_password__38-fl {
    grid-area: passwordA;
}
.LoginPage_remember_me__ig-d7 {
    grid-area: rememberA;
}

.LoginPage_login__1Yy58 {
    grid-area: loginA;
}

@media screen and (min-width: 768px) {
    .LoginPage_page__3OpUi {
        margin-top: 8rem;
    }
}
@media screen and (min-width: 1000px) {
    .LoginPage_page__3OpUi {
        margin-top: 0rem;
    }
}
.LoginPage_spinner__kPIFW {
    grid-area: loginA;
    justify-self: center;
}

.RegisterPage_page__3t2g_ {
    display: grid;
    grid-template:
        "." 6rem
        "titleA" auto
        "." 4.875rem
        "emailA" auto
        "." 0.8rem
        "passwordA" auto
        "." 0.8rem
        "confirm_passwordA" auto
        "." 0.8rem
        "rememberA" auto
        "." 1rem
        "serverA" auto
        "registerA" auto
        "." 5.75rem / 100%;
    width: 18rem;
    margin: 0 auto;
}

.RegisterPage_error__3PVHM {
    grid-area: serverA;
    font-size: 0.75rem;
    color: var(--errorColor);
    margin-bottom: 3.3rem;
}

.RegisterPage_title__1Fm12 {
    grid-area: titleA;
    color: var(--primaryColor);
    font-size: 2rem;
    text-align: center;
}

.RegisterPage_email__1kvg2 {
    grid-area: emailA;
}

.RegisterPage_password__2FzLg {
    grid-area: passwordA;
}

.RegisterPage_remember_me__1gK1Z {
    grid-area: rememberA;
}

.RegisterPage_register__1SWMm {
    grid-area: registerA;
}
.RegisterPage_confirm_password__K5--a {
    grid-area: confirm_passwordA;
}

.RegisterPage_spinner__1jU9N {
    grid-area: registerA;
    justify-self: center;
}

@media screen and (min-width: 768px) {
    .RegisterPage_page__3t2g_ {
        margin-top: 8rem;
    }
}
@media screen and (min-width: 1000px) {
    .RegisterPage_page__3t2g_ {
        margin-top: 0rem;
    }
}

.Button_button__2Ce79,
.Button_outlined_button__1Tw8n {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.5625rem;
    transition: background 0.2s, border-color 0.2s;
    border-radius: 5rem;
    font-size: 1.125rem;
    width: 100%;
}

/* .button_text {
    color: inherit;
} */

.Button_button__2Ce79 {
    background: var(--primaryColor);
    color: var(--textOnPrimaryColor);
}

.Button_outlined_button__1Tw8n {
    background: none;
    color: var(--primaryColor);
    border: 1px var(--primaryColor) solid;
}

.Button_button__2Ce79:hover,
.Button_outlined_button__1Tw8n:hover {
    background: var(--primaryHoverColor);
    cursor: pointer;
}

.Button_outlined_button__1Tw8n:hover {
    border-color: var(--primaryHoverColor);
    color: var(--textOnPrimaryColor);
}

.TextInput_text_input__2L98Q {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.875rem;
}

.TextInput_input_box__37e4u {
    box-sizing: border-box;
    display: grid;
    grid-template: "inputA helperA" auto / 1fr auto;
    align-items: baseline;
    background-color: var(--inputBoxColor);
    padding: 0.75rem 1rem 0.75rem 1.6875rem;
    border-radius: 3rem;
    border: 1px var(--inputBoxColor) solid;
}

.TextInput_helper_text__30Sec {
    opacity: 0;
    grid-area: helperA;
    padding-left: 0.2rem;
    font-size: 0.75rem;
    max-width: 4rem;
    color: var(--placeHolderColor);
}

input {
    grid-area: inputA;
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    border: none;
    border-radius: 0;
    background: none;
    font-family: "Lato", sans-serif;
    padding-bottom: 0.23rem;
    color: var(--textColor);
}
input::-webkit-input-placeholder {
    color: var(--placeHolderColor);
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
input:-ms-input-placeholder {
    color: var(--placeHolderColor);
    -ms-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
input::-ms-input-placeholder {
    color: var(--placeHolderColor);
    -ms-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
input::placeholder {
    color: var(--placeHolderColor);
    transition: opacity 0.4s;
}

input:focus::-webkit-input-placeholder {
    opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    font-family: "Lato", sans-serif;
    box-shadow: none;
    -webkit-box-shadow: 0 0 0px 1000px var(--inputBoxColor) inset;
    -webkit-text-fill-color: var(--textColor) !important;
}

.TextInput_error_text__3wfNL {
    font-size: 0.75rem;
    margin-top: 0.3rem;
    color: var(--errorColor);
    margin-left: 1.6875rem;
}

.Checkbox_check_box__KuoyL {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 0.875rem;
    color: var(--primaryColor);
    cursor: pointer;
}

.Checkbox_box__RZvQf {
    border-radius: 50%;
    border: 1px var(--primaryColor) solid;
    height: 1rem;
    width: 1rem;
    margin-right: 0.4rem;
    background-color: #ffffff;
    box-sizing: border-box;
}

.Spinner_spinner__1XGpJ {
    border: 0.2rem solid var(--inputBoxColor);
    border-top: 0.2rem solid var(--primaryColor); /* Blue */
    border-radius: 50%;
    width: 2.875rem;
    height: 2.875rem;
    box-sizing: border-box;
    -webkit-animation: Spinner_spin__1N6Nr 0.5s linear infinite;
            animation: Spinner_spin__1N6Nr 0.5s linear infinite;
}

@-webkit-keyframes Spinner_spin__1N6Nr {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes Spinner_spin__1N6Nr {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.StartPage_with_card__2Bajd {
    display: none;
}

.StartPage_logo__1uRwr {
    display: none;
}

@media screen and (min-width: 1000px) {
    .StartPage_page__1uR25 {
        display: grid;
        grid-template:
            ". . ." 7.5rem
            ". cardA ." auto
            / 1fr auto 1fr;
    }
    .StartPage_with_card__2Bajd {
        grid-area: cardA;
        display: inline-block;
    }
    .StartPage_without_card__3O-xR {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .StartPage_page__1uR25 {
        min-height: 100vh;
        grid-template:
            "logoA . ." 7.5rem
            "logoA cardA ." auto
            / 1fr auto 10%;
    }
    .StartPage_logo__1uRwr {
        grid-area: logoA;
        display: block;
        margin-top: 13.234rem;
        justify-self: center;
        width: 25rem;
    }
}

.PageSpinner_spinner__36Hsw {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.CoverItem_cover_item___enbi {
    padding: 0 3.17rem;
    display: grid;
    justify-items: center;
    grid-template:
        "." 3.1837rem
        "numberA" auto
        "." 3.17rem
        "subtitleA" auto
        "." 0.4rem
        "titleA" auto
        "." 1.5rem
        "dateA" auto
        "." 3.17rem / 100%;
    color: var(--textColor);
}

.CoverItem_date__3dOpC,
.CoverItem_subtitle__14MKG {
    font-size: 0.875rem;
}
.CoverItem_date__3dOpC {
    grid-area: dateA;
    text-align: center;
}

.CoverItem_primary__29jWW {
    color: var(--primaryColor);
}

.CoverItem_secondary__2ErRa {
    color: var(--secondaryColor);
}

.CoverItem_title__1J-F4 {
    grid-area: titleA;
    text-align: center;
    font-size: 1.25rem;
}

.CoverItem_subtitle__14MKG {
    text-align: center;
    grid-area: subtitleA;
}

.CoverItem_number__YfG3t,
.CoverItem_number_small__-5iAe {
    text-align: center;
    grid-area: numberA;
}

.CoverItem_number__YfG3t {
    font-size: 4.5rem;
}

.CoverItem_number_small__-5iAe {
    font-size: 2rem;
}

@media screen and (min-width: 768px) {
    .CoverItem_cover_item___enbi {
        grid-template:
            ". numberA" 4.125rem
            "subtitleA numberA" auto
            ". numberA" 0.25rem
            "titleA numberA" auto
            ". numberA" 0.875rem
            "dateA numberA" auto
            ". numberA" 4.06rem / 1fr auto;
    }

    .CoverItem_title__1J-F4,
    .CoverItem_subtitle__14MKG,
    .CoverItem_date__3dOpC {
        text-align: left;
        justify-self: left;
    }

    .CoverItem_number__YfG3t,
    .CoverItem_number_small__-5iAe {
        text-align: right;
        justify-self: right;
        align-self: center;
    }
}

.Dropdown_icon__H2SE6,
.Dropdown_large_icon__sO4Ni {
    cursor: pointer;
}

.Dropdown_img__1v4bB {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.Dropdown_large_icon__sO4Ni {
    width: 2.25rem;
}

.Dropdown_dropdown__3SDyp {
    position: relative;
}

.Dropdown_divider__uZYf7 {
    height: 1px;
    background: var(--inputBoxColor);
}

.Dropdown_dropdown_menu__1FfmP {
    z-index: 1;
    display: none;
    position: absolute;
    top: 110%;
    right: 0.2rem;
}

.DropdownItem_dropdown_item__1LUab {
    text-align: right;
    box-sizing: border-box;
    padding: 0.75rem;
    color: var(--primaryColor);
    cursor: pointer;
    transition: background 0.2s, color 0.2s;
    font-size: 0.875rem;
    white-space: nowrap;
}

.DropdownItem_dropdown_item__1LUab:hover {
    background: var(--primaryHoverColor);
    color: var(--textOnPrimaryColor);
}

.Tabs_tabs__33r_k {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Tabs_tab_item__2No7H {
    color: var(--iconColor);
    text-align: center;
    padding: 1.25rem 0;
    border-bottom: 1px var(--iconColor) solid;
    cursor: pointer;
}

.Tile_tile__2ux10 {
    display: flex;
    align-items: baseline;
    color: var(--textColor);
    box-sizing: border-box;
    padding: 1rem 0 1.3rem 0;
}

.Tile_title__1EM4J {
    width: 50%;
    font-size: 1.125rem;
}

.Tile_primary__1FM-n {
    color: var(--primaryColor);
}

.Tile_secondary__3rNDq {
    color: var(--secondaryColor);
}

.Tile_number__1CiyO {
    text-align: right;
    flex: 1 1;
    margin-right: 0.51rem;
    font-size: 1.5rem;
}

.Tile_dropdown__1bSmK {
    position: relative;
    top: 0.1rem;
}

.HomePage_page__2rRrU {
    padding: 3rem 11%;
}

.HomePage_main__2V4AG {
    display: grid;
    grid-template:
        "titleA" auto
        "." 3.475rem
        "coverA" auto
        "." 3.3rem
        "tabA" auto
        "." 2rem
        "tileA" auto
        "." 4rem / 100%;
}

.HomePage_title_section__3t98J {
    grid-area: titleA;
    display: flex;
    justify-content: space-between;
    color: var(--primaryColor);
    font-size: 3.5rem;
}

.HomePage_title_ld_button__3Gm-6 {
    display: none;
}

.HomePage_cover__168ng {
    grid-area: coverA;
}

.HomePage_sd_tab__Gy7wG {
    grid-area: tabA;
}

.HomePage_tile_sd__1cR9x {
    grid-area: tileA;
}

.HomePage_tile_ld__3hxIv {
    display: none;
    grid-template:
        "primary_labelA . secondary_labelA" auto
        ". . ." 2rem
        "primary_itemsA . secondary_itemsA" auto/ 1fr 4.125rem 1fr;
}

.HomePage_primary_label__3MV_T {
    grid-area: primary_labelA;
    color: var(--primaryColor);
}
.HomePage_secondary_label__WEo0Y {
    grid-area: secondary_labelA;
    color: var(--secondaryColor);
}

.HomePage_primary_items__W1kSq {
    grid-area: primary_itemsA;
}

.HomePage_secondary_items__6YFcf {
    grid-area: secondary_itemsA;
}

.HomePage_floating_button__Icsgs {
    border-radius: 5rem;
    background: transparent;
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: var(--boxshawdow);
}

.HomePage_header__3Uqo4 {
    display: none;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 4.0625rem;
    padding: 0 3rem;
    box-sizing: border-box;
}

.HomePage_navigation__2DB3i {
    font-size: 1.125rem;
    color: var(--textColor);
    cursor: pointer;
}

.HomePage_logo__4-3Xs {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: var(--primaryColor);
}

.HomePage_logo_icon__3JoLU {
    width: 2rem;
    margin-right: 0.4rem;
}

.HomePage_empty_state__34t0M {
    text-align: center;
    padding-top: 1.8rem;
    color: var(--iconColor);
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    .HomePage_page__2rRrU {
        padding: 4.375rem 7.5rem;
    }
}

@media screen and (min-width: 1000px) {
    .HomePage_empty_state__34t0M {
        text-align: left;
        justify-content: start;
    }
    .HomePage_page__2rRrU {
        padding: 0;
    }
    .HomePage_main__2V4AG {
        width: 43rem;
        margin-top: 9.857rem;
        margin-left: 8rem;
    }
    .HomePage_header__3Uqo4 {
        display: flex;
    }
    .HomePage_sd_tab__Gy7wG {
        display: none;
    }
    .HomePage_tile_sd__1cR9x {
        display: none;
    }
    .HomePage_tile_ld__3hxIv {
        grid-area: tileA;
        display: grid;
    }
    .HomePage_title_sd_dropdown__1V-Dh {
        display: none;
    }
    .HomePage_title_ld_button__3Gm-6 {
        display: block;
    }
    .HomePage_floating_button__Icsgs {
        display: none;
    }
}

@media screen and (min-width: 1500px) {
    .HomePage_main__2V4AG {
        width: 60%;
    }
}

.InsertPage_icon__ixL5S {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    height: 2rem;
    cursor: pointer;
}

.InsertPage_main__DAxqg {
    display: grid;
    grid-template:
        ". . ." 20vh
        ". titleA ." auto
        ". . ." 3.5rem
        ". inputA ." auto
        ". . ." 1rem
        ". submitA ." auto / 1fr 15rem 1fr;
}

.InsertPage_title__LK9mN {
    grid-area: titleA;
    font-size: 2rem;
    color: var(--primaryColor);
    text-align: center;
}

.InsertPage_text_input__3GQhq {
    grid-area: inputA;
}

.InsertPage_submit_button__pzLWn {
    grid-area: submitA;
}

