@import "../global.css";

.back_button {
    position: absolute;
    left: 1.375rem;
    top: 2rem;
    cursor: pointer;
}

@media screen and (min-width: 1000px) {
    .auth_panel {
        padding: 0 4rem;
        padding-bottom: 5rem;
        position: relative;
    }
    .back_button {
        top: 1.375rem;
    }
}
