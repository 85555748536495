@import "../global.css";

.tabs {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tab_item {
    color: var(--iconColor);
    text-align: center;
    padding: 1.25rem 0;
    border-bottom: 1px var(--iconColor) solid;
    cursor: pointer;
}
