@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import "./global.css";

* {
    padding: 0;
    margin: 0;
}
html {
    font-family: "Lato", sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.test_area {
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
    width: 15rem;
}
