@import "../global.css";

.button,
.outlined_button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1.5625rem;
    transition: background 0.2s, border-color 0.2s;
    border-radius: 5rem;
    font-size: 1.125rem;
    width: 100%;
}

/* .button_text {
    color: inherit;
} */

.button {
    background: var(--primaryColor);
    color: var(--textOnPrimaryColor);
}

.outlined_button {
    background: none;
    color: var(--primaryColor);
    border: 1px var(--primaryColor) solid;
}

.button:hover,
.outlined_button:hover {
    background: var(--primaryHoverColor);
    cursor: pointer;
}

.outlined_button:hover {
    border-color: var(--primaryHoverColor);
    color: var(--textOnPrimaryColor);
}
