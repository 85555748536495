:root {
    --primaryColor: #70919b;
    --secondaryColor: #6787dc;
    --inputBoxColor: #f3f3f3;
    --placeHolderColor: #949494;
    --errorColor: #ea0c26;
    --textOnPrimaryColor: #ffffff;
    --primaryHoverColor: #8eb8c6;
    --iconColor: #d1d1d1;
    --textColor: #555d60;
    --boxshawdow: 0px 5px 20px rgba(0, 0, 0, 0.06);
}
